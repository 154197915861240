/* eslint-disable react/prop-types */
import React from 'react'
import { shape } from 'prop-types'
import { graphql } from 'gatsby'
import { motion } from 'framer-motion'
import { fade } from '@/utils/transitions'
import { useLocation } from "@reach/router"
import SEO from '@/components/Seo'
import useConstant from '@/hooks/useConstant'
import Blocks from '@/utils/Blocks'
import { cleanPropsWithRelations } from '@/utils'
/* eslint-disable no-unused-vars */
import {
	caseStudyQuery,
	quoteQuery,
	postThumbQuery,
	postQuery
} from '@/queries'
/* eslint-enable no-unused-vars */
import * as T from '@/types'

function NewsPost({
	pageContext: { media, wordpress_id, date, categories },
	data: {
		page: { blocks, featured_image_url, yoast, featured_media },
		caseStudies: { nodes: caseStudies },
		posts: { nodes: posts },
		quotes: { nodes: quotes }
	}
}) {

	const showDate = categories.map(c => c.name).includes('Resources')
	const formattedDate = new Date(Date.parse(date)).toLocaleString("en-gb", { year: "numeric", month: "long", day: "numeric" })
	let data = useConstant(() =>
		cleanPropsWithRelations(blocks, media, {
			'acf/case-study-card-slider': {
				data: [...caseStudies, ...posts],
				key: 'case_studies'
			},
			'acf/quote-collection-block': { data: quotes, key: 'quotes' }
		})
	)

	const images = useConstant(() => JSON.parse(media))

	// scroll offset for hash links
	const location = useLocation()
	if (location.hash) {
		const el = document.querySelector(location.hash);
		if(el) window.scroll({ top: (el.offsetTop - 70), left: 0, behavior: 'smooth' });
	}

	data = data.map(block => {
		if (!block.type === 'acf/article-hero' ||
			!block.article_hero_background_image ||
			!images[block.article_hero_background_image]
		) return block
		return {
			...block,
			article_hero_background_image: { ...images[block.article_hero_background_image] }
		}
	})

	data = showDate
		? data
		: [
			data.shift(), // move hero to the top
			{ type: 'core/paragraph', content: `<p><strong>Posted; ${ formattedDate }</strong></p>` },
			...data
		]

	return (
		<>
			<SEO { ...yoast } image={ featured_media } />
			<Blocks data={ data } media={ media } currentPage={ wordpress_id } />
		</>
	)
}

NewsPost.propTypes = {
	pageContext: T.pageContext.isRequired,
	data: shape({
		page: T.post.isRequired,
		caseStudies: T.caseStudies.isRequired,
		posts: T.posts.isRequired,
		quotes: T.quotes.isRequired
	})
}

export const query = graphql`
	query NewsPostQuery($path: String!) {
		quotes: allWordpressWpQuotes(limit: 100) {
			nodes {
				...quoteQuery
			}
		}

		caseStudies: allWordpressWpCaseStudy(filter: { slug: { ne: "template" } }) {
			nodes {
				...caseStudyQuery
			}
		}

		posts: allWordpressPost(
			filter: { slug: { ne: "all-component-block-guide" } }
		) {
			nodes {
				...postThumbQuery
			}
		}
		page: wordpressPost(path: { eq: $path }) {
			...postQuery
		}
	}
`

export default NewsPost
